import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { withTranslation } from 'react-i18next';

const Link = ({ to, children, t, tReady, i18n, ...props }) => {
  const path = i18n.language === 'el' ? to : `/${i18n.language}${to}`;

  return (
    <GatsbyLink to={path} {...props}>
      {children}
    </GatsbyLink>
  );
};

export default withTranslation()(Link);
