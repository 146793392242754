import React, { Component } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { Box, Card, Image, Flex, Text } from 'rebass';
import { withTranslation } from 'react-i18next';
import { Location } from '@reach/router';
import dayjs from 'dayjs';
import 'dayjs/locale/el';
import { MenuItem, MenuItemExpandable } from '../Sidebar/Sidebar';

import FlagEl from './flags/flag-gr.svg';
import FlagEn from './flags/flag-eng.svg';

const StyledCard = styled(Card)`
  height: 16px;
  display: inline-flex;
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '27px')};
  vertical-align: baseline;
`;

const SubmenuIcon = styled.i`
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  margin-left: 5px;
  padding: 2.5px;
  object-fit: contain;
`;

const SubmenuIconOpen = styled(SubmenuIcon)`
  margin-bottom: 3px;
  transform: rotate(45deg);
`;

const SubmenuIconClose = styled(SubmenuIcon)`
  transform: rotate(-135deg);
`;

class LocaleSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      languageMenuOpen: false,
    };

    this.i18n = this.props.i18n;

    this.toggleLanguageMenu = this.toggleLanguageMenu.bind(this);
    this.setLocale = this.setLocale.bind(this);
  }

  getLocale() {
    return this.i18n.language;
  }

  setLocale(locale, path) {
    this.setState({
      languageMenuOpen: false,
    });
    if (locale !== this.i18n.language) {
      if (locale === 'en') {
        navigate(`${locale}/${path}`);
      } else {
        const to = path.substr(3, path.length);
        navigate(to);
      }
      this.i18n.changeLanguage(locale);
      dayjs.locale(locale)
    }
  }

  isLanguageMenuOpen() {
    return this.state.languageMenuOpen;
  }

  toggleLanguageMenu(event) {
    this.setState({ languageMenuOpen: !this.state.languageMenuOpen });
  }

  render() {
    let menu;
    const navbarMode = this.props.mode && this.props.mode === 'navbar';

    if (navbarMode) {
      return (
        <Location>
          {({ location }) => (
            <StyledCard paddingLeft={['0', '10px', '27px', '27px']}>
              <Box onClick={this.toggleLanguageMenu}>
                {this.getLocale() === 'el' && (
                  <Image ml="7px" src={FlagEl} alt="Greek Flag"></Image>
                )}
                {this.getLocale() === 'en' && (
                  <Image ml="7px" src={FlagEn} alt="English Flag"></Image>
                )}

                {!this.isLanguageMenuOpen() && <SubmenuIconOpen />}
                {this.isLanguageMenuOpen() && <SubmenuIconClose />}
              </Box>
              {this.isLanguageMenuOpen() && (
                <Flex
                  flexDirection="column"
                  mt="30px"
                  ml="7px"
                  css={{ position: 'absolute' }}
                >
                  {this.getLocale() !== 'el' && (
                    <Flex
                      onClick={() => this.setLocale('el', location.pathname)}
                    >
                      <Image
                        py="3px"
                        height="28px"
                        src={FlagEl}
                        alt="Greek Flag"
                        css={{ cursor: 'pointer' }}
                      />
                    </Flex>
                  )}
                  {this.getLocale() !== 'en' && (
                    <Flex
                      onClick={() => this.setLocale('en', location.pathname)}
                    >
                      <Image
                        py="3px"
                        height="28px"
                        src={FlagEn}
                        alt="English Flag"
                        css={{ cursor: 'pointer' }}
                      />
                    </Flex>
                  )}
                </Flex>
              )}
            </StyledCard>
          )}
        </Location>
      );
    } else {
      return (
        <Location>
          {({ location }) => (
            <MenuItemExpandable name={this.i18n.t('locale_selector.language')}>
              <MenuItem
                as="a"
                onClick={() => this.setLocale('en', location.pathname)}
              >
                <Text color="white">
                  English
                  <Image pl="5px" src={FlagEn} alt="English Flag"></Image>
                </Text>
              </MenuItem>
              <MenuItem
                as="a"
                onClick={() => this.setLocale('el', location.pathname)}
              >
                <Text color="white">
                  Ελληνικά<Image pl="5px" src={FlagEl} alt="Greek Flag"></Image>
                </Text>
              </MenuItem>
            </MenuItemExpandable>
          )}
        </Location>
      );
    }
  }
}
export default withTranslation()(LocaleSelector);
