import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';
import { Card } from 'rebass';
import { slide as menu } from 'react-burger-menu';
import { useIdentityContext } from 'react-netlify-identity';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

import { Link } from '../Link';
import MobileBars from './icons/mobile-bars.svg';
import LocaleSelector from '../LocaleSelector';
import { client } from '../../graphql/client';
import { useGlobalContext } from '../../GlobalState';

/* Customer Support Menu with Submenu items */
const SubmenuIcon = styled.i`
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  margin-left: 7px;
  padding: 2.5px;
`;

const SubmenuIconOpen = styled(SubmenuIcon)`
  margin-bottom: 3px;
  transform: rotate(45deg);
`;

const SubmenuIconClose = styled(SubmenuIcon)`
  transform: rotate(-135deg);
`;

/* Main Menu items */
export const MenuItemBase = css`
  width: 100%;
  padding-left: 19px;
  padding-right: 22px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: right;
  display: block;
  :focus {
    outline: none !important;
    background-color: none;
  }
`;

/* Menu item shown on all users */
export const MenuItemGuest = css`
  ${MenuItemBase};
  height: 52px;
  padding-top: 20px;
  font-size: 12px;
  font-weight: bold;
  color: ${props => props.theme.colors.white};
  :hover {
    text-decoration: none !important;
    color: white;
    background-color: ${props => props.theme.colors.blues.medium};
  }
`;

/* Menu item shown on all users */
const CustomLink = ({ className, onclickFn, children, to }) => (
  <Link onClick={onclickFn} className={className} to={to}>
    {children}
  </Link>
);

export const MenuItem = styled(CustomLink)`
  ${MenuItemGuest};
`;

const CustomExternalLink = ({
  className,
  onclickFn,
  children,
  href,
  target,
}) => (
  <a className={className} href={href} target={target}>
    {children}
  </a>
);

export const MenuItemExternal = styled(CustomExternalLink)`
  ${MenuItemGuest};
`;

/* Menu item with submenu */
export const MenuItemExpandable = ({ name, children }) => {
  const [expanded, expandMenu] = useState(false);
  return (
    <>
      <MenuItem as="div" onClick={() => expandMenu(!expanded)}>
        {name}
        {expanded ? <SubmenuIconClose /> : <SubmenuIconOpen />}
      </MenuItem>
      {expanded ? children : null}
    </>
  );
};

/* Menu item shown on logged in users */
const MenuItemLoggenIn = styled(CustomLink)`
  ${MenuItemBase};
  height: 44px;
  padding-top: 13px;
  font-size: 14px;
  font-weight: normal;
  background-color: ${props => props.theme.colors.maize};
  color: ${props => props.theme.colors.blues.peacock};
  :hover {
    text-decoration: none !important;
    color: white;
    background-color: ${props => props.theme.colors.blues.medium};
  }
`;

const Menu = styled(menu)`
  background-color: ${props => props.theme.colors.blues.twilight};
  padding-top: 60px;
`;

const LoggedOnBadge = styled(Card)`
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  // position: fixed;
  // top: 31px;
  // right: 22px;
`;

const Sidebar = props => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [initMenu, setinitMenu] = useState(true);
  const identity = useIdentityContext();
  const { removeUser } = useGlobalContext();
  const { isLoggedIn, logoutUser } = identity;
  const { t } = useTranslation();

  const handleStateChange = state => {
    // Needs to be used along with closeMenu()
    // on MenuItem(LoggedIn) onClick event
    // otherwise, page will not scroll on mobile after menu item click
    //
    // document.querySelector('html').style['overflow-y'] = menuOpen
    //   ? 'auto'
    //   : 'hidden';
    setMenuOpen(state.isOpen);
  };

  // const closeMenu = () => {
  //   document.querySelector('html').style['overflow-y'] = 'auto';
  //   setMenuOpen(false);
  // };

  const menuStyles = {
    bmBurgerButton: {
      position: 'fixed',
      width: '19px',
      height: '19px',
      top: '23px',
      right: '20px',
      zIndex: 'auto',
    },
    bmCrossButton: {
      position: 'absolute',
      width: '19px',
      height: '19px',
      left: '20px',
      top: '23px',
    },
    bmCross: {
      background: 'white',
    },
    bmMenuWrap: {
      position: 'fixed',
      top: 0,
    },
  };

  return (
    <Menu
      styles={menuStyles}
      right
      slide
      noOverlay
      disableAutoFocus
      isOpen={menuOpen}
      width={props.width ? props.width : '100%'}
      outerContainerId={'outer-container'}
      pageWrapId={'page-wrap'}
      customBurgerIcon={<img src={MobileBars} alt="Open Menu" />}
      onStateChange={state => handleStateChange(state)}
    >
      <MenuItemExternal
        href="https://wbx-prod.qualco.eu/deliverd-webshipping/#"
        target="_blank"
      >
        {t('navbar.company_login')}
      </MenuItemExternal>
      <LoggedOnBadge>
        {!isLoggedIn && (
          <MenuItem to="/sign_in">{t('sidebar.sign_in')}</MenuItem>
        )}
      </LoggedOnBadge>
      <MenuItem to="/send_a_box">{t('sidebar.send_a_new_box')}</MenuItem>
      {/* <MenuItem to="/pickup_your_box">{t('sidebar.pick_your_box')}</MenuItem> */}
      {/* <MenuItem to="/box_tracking">{t('sidebar.weboxit_tracking')}</MenuItem> */}
      <MenuItemExternal
        href="https://wbx-prod.qualco.eu/deliverd-webhosting/tracking"
        target="_blank"
      >
        {t('navbar.weboxit_tracking')}
      </MenuItemExternal>
      <MenuItemExpandable name={t('sidebar.why_weboxit')}>
        <MenuItem to="/why_weboxit/4_steps_tutorial">
          {t('sidebar.four_steps_tutorial')}
        </MenuItem>
        {/* <MenuItem to="/why_weboxit/smartest_box_delivery">
          {t('sidebar.smartest_box_delivery')}
        </MenuItem> */}
        <MenuItem to="/why_weboxit/for_customers">
          {t('sidebar.for_customers')}
        </MenuItem>
        <MenuItem to="/why_weboxit/for_eshops_and_businesses">
          {t('sidebar.smartest_box_delivery')}
        </MenuItem>
      </MenuItemExpandable>
      <MenuItemExpandable name={t('sidebar.customer_support')}>
        <MenuItem to="/customer_support/24_7_live_support">
          {t('sidebar.support')}
        </MenuItem>
        <MenuItem to="/customer_support/faq">{t('sidebar.faq')}</MenuItem>
        {/* <MenuItem to="/customer_support/contact_us">
          {t('sidebar.contact_us')}
        </MenuItem> */}
      </MenuItemExpandable>
      {isLoggedIn && (
        <MenuItemExpandable name={t('sidebar.my_boxit')}>
          <MenuItemLoggenIn
            onclickFn={() => {
              setMenuOpen(false);
            }}
            to="/my_boxit/summary"
          >
            {t('sidebar.logged_in.summary')}
          </MenuItemLoggenIn>
          <MenuItemLoggenIn
            onclickFn={() => {
              setMenuOpen(false);
            }}
            to="/my_boxit/sending"
          >
            {t('sidebar.logged_in.sending')}
          </MenuItemLoggenIn>
          <MenuItemLoggenIn
            onclickFn={() => {
              setMenuOpen(false);
            }}
            to="/my_boxit/account"
          >
            {t('sidebar.logged_in.account_details')}
          </MenuItemLoggenIn>
          <MenuItemLoggenIn
            as="a"
            href="/"
            onClick={async event => {
              event.preventDefault();
              await logoutUser();
              client.clearStore();
              removeUser();
              navigate(
                i18n.language === 'en'
                  ? `${'/' + i18n.language}/sign_in`
                  : '/sign_in'
              );
            }}
          >
            {t('sidebar.logged_in.logout')}
          </MenuItemLoggenIn>
        </MenuItemExpandable>
      )}
      <LocaleSelector mode="sidebar" />
    </Menu>
  );
};

export default Sidebar;
