import styled from 'styled-components';
import { theme } from '../theme';

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true,
    });
  } else {
    obj[key] = value;
  }
  return obj;
}

var hiddenBreakpoints = {
  xs: '@media screen and (max-width: 40em)',
  sm: '@media screen and (min-width: 40em) and (max-width: 48em)',
  md: '@media screen and (min-width: 48em) and (max-width: 80em)',
  lg: '@media screen and (min-width: 80em)',

  mobile: '@media screen and (max-width: ' + theme.breakpoints[0] + ')',
  tablet:
    '@media screen and (min-width: ' +
    theme.breakpoints[0] +
    ') and (max-width: ' +
    theme.breakpoints[1] +
    ')',
  desktop: '@media screen and (min-width: ' + theme.breakpoints[1] + ')',
};

var hidden = function hidden(key) {
  return function(props) {
    return props[key]
      ? _defineProperty({}, hiddenBreakpoints[key], {
          display: 'none',
        })
      : null;
  };
};

var xs = hidden('xs');
var sm = hidden('sm');
var md = hidden('md');
var lg = hidden('lg');

var mobile = hidden('mobile');
var tablet = hidden('tablet');
var desktop = hidden('desktop');

export const Hide = styled.div([], xs, sm, md, lg, mobile, tablet, desktop);

export default Hide;
