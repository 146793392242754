import React from 'react';
import { Flex, Box, Text, Card, Image } from 'rebass';
import { useTranslation } from 'react-i18next';

import { Container } from '../container';
import { Hide } from '../hide';
import { Button } from '../Button';
import { Link } from '../Link';
import BoxitLogo from '../Logo';

import BackgroundTriangle from './icons/footer-triangle.svg';
import BackgroundTriangleMobile from './icons/footer-triangle-mobile.svg';
import CardFAQTriangle from './icons/footer-card-triangle.svg';

import Image1 from '../../icons/SecureCode/sc_62x34.gif';
import Image2 from '../../icons/CardsIcons/Maestro.png';
import Image3 from '../../icons/CardsIcons/Mastercard.png';
import Image4 from '../../icons/CardsIcons/Amex.jpg';
import Image5 from '../../icons/CardsIcons/Visa.jpg';
import Image6 from '../../icons/VbV/vbv.jpg';
import Image7 from '../../icons/PiraeusBank/PB_white_EN.png';

const FooterCard = ({ children, backgroundImage, mobile, height }) => (
  <Box width={[1, 1 / 3.1, 1 / 3.1]} pb={['20px', 0, 0]}>
    {backgroundImage ? (
      <Card
        backgroundImage={'url(' + backgroundImage + ')'}
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition="top center"
        bg={'white'}
        css={{
          minHeight: height ? height : '',
          border: '1px solid #cfd4dc',
          borderRadius: '10px',
          opacity: backgroundImage || mobile ? '1' : '0.92',
        }}
      />
    ) : (
      <Card
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition="top center"
        bg={'white'}
        css={{
          minHeight: height ? height : '',
          border: '1px solid #cfd4dc',
          borderRadius: '10px',
          opacity: backgroundImage || mobile ? '1' : '0.92',
        }}
      />
    )}
    <Flex
      px={['18px', '15px', '30px']}
      py={['25px', '10px', '20px']}
      flexDirection="column"
      css={{
        position: 'relative',
        top: '-' + height,
        marginBottom: '-' + height,
      }}
    >
      {children}
    </Flex>
  </Box>
);

const FooterCardContent = ({ children, textAlign, mobile }) => (
  <Text
    pt={[0, 2, 0]}
    pb={[4, 2, 4]}
    color="blues.peacock"
    fontSize={['14px', '12px', '17px']}
    letterSpacing="normal"
    lineHeight={mobile ? '1.33' : '1.41'}
    textAlign={textAlign ? textAlign : 'center'}
    css={{ minHeight: '60px' }}
  >
    {children}
  </Text>
);

const FooterCardButton = ({ mobile = false, children, url }) => (
  <Link to={url}>
    <Button
      width="100%"
      height="auto"
      marginTop="auto"
      fontSize="12px"
      lineHeight="normal"
      letterSpacing={mobile ? '1.5px' : 'normal'}
      padding={['13px 0 12px 0', '7px 0 5px', '12px 0 9px']}
      border="none"
    >
      {children}
    </Button>
  </Link>
);

function FooterCards({ mobile = false, height = '150px' }) {
  const { t, i18n } = useTranslation();

  return (
    <Flex
      pt={['30px', '56px', 6]}
      justifyContent="space-between"
      flexDirection={mobile ? 'column' : 'row'}
    >
      <FooterCard
        backgroundImage={CardFAQTriangle}
        mobile={mobile}
        height={height}
      >
        <Text
          pt={[0, 0, 3]}
          pb={['15px', '12px', '55px']}
          width={[1, 3 / 4, 1]}
          mx="auto"
          color="blues.peacock"
          fontSize={['16px', 2, 3]}
          fontWeight="bold"
          letterSpacing="0.5"
          lineHeight="1"
          textAlign="center"
          css={{ minHeight: mobile ? '70px' : '40px' }}
        >
          {t('footer.faq.title')}
        </Text>
        <FooterCardContent mobile={mobile} height={mobile ? '225px' : height}>
          {t('footer.faq.content')}
        </FooterCardContent>
        <FooterCardButton mobile={mobile} url="/customer_support/faq">
          {t('footer.faq.button')}
        </FooterCardButton>
      </FooterCard>

      <FooterCard mobile={mobile} height={mobile ? '215px' : height}>
        <Flex
          pt={[0, 3, 3]}
          pb={['28px', '2px', '44px']}
          mx="auto"
          flexDirection={i18n.language === 'en' ? 'row' : 'row-reverse'}
        >
          <Text
            color="maize"
            fontSize={['28px', '22px', '34px']}
            fontWeight="bold"
            lineHeight={mobile ? '0.82' : '0.7'}
            letterSpacing={mobile ? '-0.5px' : '-0.3px'}
            textAlign="center"
            css={{ minHeight: '22px' }}
          >
            {t('footer.support.title')}
          </Text>
          <Text
            color="blues.peacock"
            pl={['0.1em', '0.3em', '0.1em']}
            fontSize={['28px', '22px', '34px']}
            fontWeight="bold"
            lineHeight={mobile ? '0.82' : '0.7'}
            letterSpacing={mobile ? '-0.5px' : '-0.3px'}
            textAlign="center"
          >
            {t('footer.support.subtitle')}
          </Text>
        </Flex>
        <FooterCardContent
          textAlign={mobile ? 'center' : 'left'}
          mobile={mobile}
        >
          {t('footer.support.content')}
        </FooterCardContent>
        <Box pt={[0, 3, 0]}>
          <FooterCardButton mobile={mobile} url="/customer_support/">
            {t('footer.support.button')}
          </FooterCardButton>
        </Box>
      </FooterCard>

      <FooterCard mobile={mobile} height={mobile ? '170px' : height}>
        <Text
          pt={[0, 1, 3]}
          pb={[0, 2, 3]}
          color="blues.peacock"
          fontSize={['13px', '13px', '22px']}
          fontWeight="600"
          letterSpacing="0.5"
          lineHeight="1.1"
          textAlign={mobile ? 'center' : 'left'}
        >
          {t('footer.legal_info.title')}
        </Text>
        <Text
          pt={[3, 3, '15px']}
          color="#456b8c"
          fontSize={['12px', 1, 2]}
          lineHeight={['1.33', '1.33', '2.07']}
          textAlign="left"
          css={{ minHeight: '110px' }}
        >
          <ul style={{ paddingInlineStart: '18px' }}>
            <li>
              <a href="/weboxit_service_terms.pdf" target="_blank">
                <Text
                  color="#456b8c"
                  fontSize={['12px', 1, 2]}
                  lineHeight={['1.33', '1.33', '2.07']}
                  textAlign="left"
                >
                  {t('footer.legal_info.service_terms')}
                </Text>
              </a>
            </li>
            <li>
              <a href="/weboxit_website_terms.pdf" target="_blank">
                <Text
                  color="#456b8c"
                  fontSize={['12px', 1, 2]}
                  lineHeight={['1.33', '1.33', '2.07']}
                  textAlign="left"
                >
                  {t('footer.legal_info.website_terms')}
                </Text>
              </a>
            </li>
            <li>
              <a href="/weboxit_privacy_policy.pdf" target="_blank">
                <Text
                  color="#456b8c"
                  fontSize={['12px', 1, 2]}
                  lineHeight={['1.33', '1.33', '2.07']}
                  textAlign="left"
                >
                  {t('footer.legal_info.privacy_notice')}
                </Text>
              </a>
            </li>
            <li>
              <a href="/weboxit_general_terms.pdf" target="_blank">
                <Text
                  color="#456b8c"
                  fontSize={['12px', 1, 2]}
                  lineHeight={['1.33', '1.33', '2.07']}
                  textAlign="left"
                  css={{ minHeight: '110px' }}
                >
                  {t('footer.legal_info.general_terms')}
                </Text>
              </a>
            </li>
            {/* <li>{t('footer.legal_info.refund_policy')}</li> */}
            {/* <li>{t('footer.legal_info.protect_against_fraud')}</li> */}
          </ul>
        </Text>
      </FooterCard>
    </Flex>
  );
}

const PaymentMethods = ({ mobile }) => {
  const { t } = useTranslation();
  return (
    <Box alignItems={mobile ? 'center' : ''}>
      <Text
        color="white"
        fontSize="11px"
        lineHeight={mobile ? '3.09' : '2.0'}
        textAlign={mobile ? 'center' : ''}
      >
        {t('footer.paymentMethods')}
      </Text>
      <Flex pt="5px">
        <Image style={{ height: '24px' }} src={Image1} />
        <Image pl="4px" style={{ height: '24px' }} src={Image2} />
        <Image pl="4px" style={{ height: '24px' }} src={Image3} />
        <Image pl="4px" style={{ height: '24px' }} src={Image5} />
        <Image pl="4px" style={{ height: '24px' }} src={Image6} />
        <Image pl="4px" style={{ height: '24px' }} src={Image4} />
      </Flex>
    </Box>
  );
};

function Copyright({ mobile }) {
  const { t } = useTranslation();

  return (
    <Flex
      width={1}
      alignSelf="flex-end"
      pt={['58px', '12px', '58px']}
      pb={['31px', '30px', '48px']}
      flexDirection={mobile ? 'column' : 'row'}
      alignItems={mobile ? 'center' : ''}
    >
      <Box pb={mobile ? '31px' : 0} width={['130px', '72px', '164px']}>
        <BoxitLogo />
      </Box>
      <Text
        width={1}
        color="white"
        fontSize="11px"
        lineHeight={mobile ? '3.09' : '2.0'}
        textAlign={mobile ? 'center' : 'center'}
      >
        {t('footer.copyright', { year: new Date().getFullYear() })}
      </Text>
      <PaymentMethods mobile={mobile} />
    </Flex>
  );
}

export const Footer = () => (
  <footer>
    {/* Mobile */}
    <Hide tablet desktop>
      <Container pb={[0, 10, 10]}>
        <FooterCards mobile={true} height="225px" />
      </Container>
      <Card
        mt={['-10px', 0]}
        backgroundImage={'url(' + BackgroundTriangleMobile + ')'}
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition="top center"
      >
        <Flex width={1} flexDirection="row">
          <Copyright mobile={true} />
        </Flex>
      </Card>
    </Hide>
    {/* Tablet / Desktop */}
    <Hide mobile>
      <Card
        backgroundImage={'url(' + BackgroundTriangle + ')'}
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition="top center"
        css={{ maxWidth: '1440px' }}
        mx="auto"
      >
        <Container>
          {/* Tablet */}
          <Hide desktop>
            <FooterCards height="150px" />
          </Hide>
          {/* Desktop */}
          <Hide tablet>
            <FooterCards height="255px" />
          </Hide>
          <Flex width={1} flexDirection="row">
            <Copyright mobile={false} />
          </Flex>
        </Container>
      </Card>
    </Hide>
  </footer>
);
