import { useEffect } from 'react';
import axios from 'axios';

//const STATIONS_URL = 'http://51.103.54.151:4000/stations';

const StationsListFetch = () => {
  useEffect(() => {
    const getStations = async () => {
      try {
        const instance = axios.create({
          baseURL: 'https://edge.weboxit.com/api/',
        });

        const res = await instance.get('/stations');
        const stationsJ = res.data;
        window.stations = stationsJ;
      } catch (e) {
        console.log('errror getting StationList');
      }
    };
    if (!window.stations) {
      getStations();
    }

    // Update the document title using the browser API
  });
  return null;
};

export default StationsListFetch;
