import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';
import { useTranslation } from 'react-i18next';
import { useIdentityContext } from 'react-netlify-identity';

import { Link } from '../Link';
import { Container } from '../container';
import { Sidebar } from '../Sidebar';
import BoxitLogo from '../Logo/BoxitLogo';
import LocaleSelector from '../LocaleSelector';
import Hide from '../hide';

// import ProfileIcon from '../../icons/profile.svg';

const NavLink = ({ to, children }) => (
  <NavLinkBase
    to={to}
    activeClassName="navbar-active-link"
    partiallyActive={true}
  >
    {children}
  </NavLinkBase>
);

export const NavLinkBase = styled(Link)`
  height: 24px;
  font-family: 'PF BeauSans Pro', -apple-system, sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #ffffff;
  display: inline-flex;
  padding-left: 22px;
  width: inherit;
  :hover {
    color: ${props => props.theme.colors.maize};
  }
`;

export const ExternalLinkBase = styled.a`
  height: 24px;
  font-family: 'PF BeauSans Pro', -apple-system, sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #ffffff;
  display: inline-flex;
  padding-left: 22px;
  width: inherit;
  :hover {
    color: ${props => props.theme.colors.maize};
  }
`;

export default function Navbar({ transparent, loggedOn = false }) {
  const [navBackground, setNavBackground] = useState(false);
  const navRef = useRef();

  const { isLoggedIn } = useIdentityContext();
  const { t } = useTranslation();

  navRef.current = navBackground;

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 10;
      if (navRef.current !== show) {
        setNavBackground(show);
      }
    };
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Container
      bg={transparent && !navBackground ? '' : '#0b5190'}
      css={{ transition: '1s ease' }}
    >
      <Flex
        mx={'auto'}
        pt={['24px', '24px', '32px']}
        pb={['22px', '16px', '16px']}
        alignItems="center"
      >
        {/* <BoxitLogo height="14px" /> */}
        <BoxitLogo mt={['-2px', '-7px', '-7px']} />
        <Hide mobile>
          <NavLink to="/send_a_box">{t('navbar.send_a_new_box')}</NavLink>
          {/* <NavLink to="/pickup_your_box">{t('navbar.pick_your_box')}</NavLink> */}
          <ExternalLinkBase
            href="https://wbx-prod.qualco.eu/deliverd-webhosting/tracking"
            target="_blank"
          >
            {t('navbar.weboxit_tracking')}
          </ExternalLinkBase>

          {/* <NavLink to="/box_tracking">{t('navbar.weboxit_tracking')}</NavLink> */}
        </Hide>
        <Box mx="auto" />
        <Hide mobile tablet>
          <NavLink to="/why_weboxit/">{t('navbar.why_weboxit')}</NavLink>
          <NavLink to="/customer_support/">
            {t('navbar.customer_support')}
          </NavLink>
          <ExternalLinkBase
            href="https://wbx-prod.qualco.eu/deliverd-webshipping/#"
            target="_blank"
          >
            {t('navbar.company_login')}
          </ExternalLinkBase>

          {isLoggedIn ? (
            <NavLink to="/my_boxit/summary">
              {t('navbar.my_boxit')}
              {/* {`${getUser().name}`} */}
              {/* <Image src={ProfileIcon} /> */}
            </NavLink>
          ) : (
            <NavLink to="/sign_in">{t('navbar.sign_in')}</NavLink>
          )}

          <LocaleSelector mode="navbar" />
        </Hide>
        <Hide tablet desktop>
          <Sidebar loggedOn={loggedOn} />
        </Hide>
        <Hide mobile desktop>
          <Sidebar loggedOn={loggedOn} width="30%" />
        </Hide>
      </Flex>
    </Container>
  );
}

export const NavbarBranded = ({ navLinks }) => (
  <Navbar>
    <BoxitLogo />
    {navLinks.map(navLink => (
      <NavLink>{navLink}</NavLink>
    ))}
  </Navbar>
);
