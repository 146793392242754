import React from "react"
import { useIdentityContext } from 'react-netlify-identity';
import { navigate } from 'gatsby';
import { client } from '../graphql/client';
import i18n from '../i18n';
import { useGlobalContext } from "../GlobalState";

const CheckLogout = ()=>{
    const identity = useIdentityContext();
    const { removeUser } = useGlobalContext()
    const { isLoggedIn, logoutUser } = identity;

    return <AutoLogout removeUser={removeUser} logoutUser={logoutUser} isLoggedIn={isLoggedIn} />
}

class AutoLogout extends React.Component {
    constructor(props) {
        super(props);

        this.state = { logginStatus: false };
        this.events = [
            "load",
            "mousemove",
            "mousedown",
            "click",
            "scroll",
            "keypress"
          ];
      
        this.warn = this.warn.bind(this);
        this.logout = this.logout.bind(this);
        this.resetTimeout = this.resetTimeout.bind(this);
    }
  
    componentDidMount() {
        // console.log('comp did mount ', this.props.isLoggedIn)
        this.setState({logginStatus: this.props.isLoggedIn})
        if(this.props.isLoggedIn){
    
            for (var i in this.events) {
                window.addEventListener(this.events[i], this.resetTimeout);
            }
    
            this.setTimeout();
        }else{
            for (var i in this.events) {
                window.removeEventListener(this.events[i], this.resetTimeout);
            }

           this.clearTimeout() 
        }
    }

    componentWillUnmount() {
        for (var i in this.events) {
            window.removeEventListener(this.events[i], this.resetTimeout);
        }
        this.clearTimeout() 
    }

    clearTimeout() {
      if (this.warnTimeout) clearTimeout(this.warnTimeout);
      if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
    }
  
    setTimeout() {
      this.warnTimeout = setTimeout(this.warn, 9 * 60 * 1000);
      this.logoutTimeout = setTimeout(this.logout, 10 * 60 * 1000);
    }
  
    resetTimeout() {
      this.clearTimeout();
      this.setTimeout();
    }
  
    warn() {
      console.log("You will be logged out automatically in 10 minute.");
    }
  
    logout() {
      // Send a logout request to the API
      //console.log("Sending a logout request to the API...");
      //console.log(this.state.logginStatus)
      if(this.state.logginStatus){
        this.setState({ logginStatus: false });
        this.destroy(); // Cleanup
      }
    }
  
    async destroy() {
        this.clearTimeout();
        for (var i in this.events) {
            window.removeEventListener(this.events[i], this.resetTimeout);
        }
        await this.props.logoutUser();
        client.clearStore()
        this.props.removeUser()
        navigate((i18n.language === 'en')? `${'/'+i18n.language}/sign_in`: '/sign_in');
        
    }

    render() {
      return null
    }
  }

export default CheckLogout;





