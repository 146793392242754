import React from 'react';
import styled from 'styled-components';
import { Card, Flex, Heading, Text } from 'rebass';

import { Container } from '../container';
import { Link } from '../Link';
import Hide from '../hide';

import MaskIcn from '../../icons/mask@2x.png';

const PageHeader = props => (
  <Flex
    {...props}
    pt={['70px', '69px', '77px']}
    bg="maize"
    mx="auto"
    css={{
      maxWidth: '1440px',
    }}
  />
);

const Title = props => (
  <Heading
    lineHeight="0.44"
    letterSpacing="-1.1px"
    color="blues.twilight"
    {...props}
  />
);

const Sub = props => (
  <Text {...props} mt="27px" mb="40px" lineHeight="1.5" color="blues.peacock" />
);

export const NavLink = styled(Link)`
  margin-top: 48px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.14;
  letter-spacing: normal;
  color: ${props => props.theme.colors.blues.peacock};

  :hover {
    font-style: underlined;
  }
`;

export const Header = ({ title, subtitle }) => (
  <PageHeader>
    <Card
      width="100%"
      backgroundImage={'url(' + MaskIcn + ')'}
      backgroundSize="auto"
      backgroundRepeat="no-repeat"
      backgroundPosition="bottom right"
    >
      <Container>
        <Title
          mt={['48px', '94px', '114px']}
          fontSize={['20px', '42px', '57px']}
        >
          {title}
        </Title>
        <Sub fontSize={['12px', '14px', '20px']}>{subtitle}</Sub>
      </Container>
    </Card>
  </PageHeader>
);

const TitleCompact = props => (
  <Title
    mt="46px"
    fontSize="30px"
    lineHeight="0.83"
    color="blues.peacock"
    {...props}
  />
);

const PageHeaderCompact = props => (
  <PageHeader
    {...props}
    css={{
      height: '177px',
    }}
  />
);

export const HeaderCompact = ({ title, subtitle, children }) => (
  <PageHeaderCompact>
    <Container>
      <Hide mobile tablet>
        <Flex
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
        >
          <TitleCompact>{title}</TitleCompact>
          {children}
        </Flex>
      </Hide>
      <Hide desktop>
        <Title
          mt="20px"
          fontSize="12px"
          lineHeight="normal"
          letterSpacing="-0.16px"
        >
          {title}
        </Title>
        <TitleCompact
          mt="12px"
          fontSize={['20px', '30px']}
          letterSpacing="normal"
          lineHeight="1"
        >
          {subtitle}
        </TitleCompact>
      </Hide>
    </Container>
  </PageHeaderCompact>
);
