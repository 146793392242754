import React, { Component } from 'react';

import i18n from './i18n';
import dayjs from 'dayjs';
import 'dayjs/locale/el';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import GlobalStyle from './global-style';

import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';
import AutoLogout from './components/auto_logout'
import StationsListFetch from './components/StationListFetch'

const HeaderWrapper = styled.div`
  position: fixed;
  z-index: 9;
  padding: 0;
  width: inherit;
  object-fit: contain;
  width: 100%;
`;

const Header = ({ transparent }) => (
  <HeaderWrapper>
    <Navbar transparent={transparent} />
  </HeaderWrapper>
);

export class Layout extends Component {
  constructor(props) {
    super(props);
    const { pageContext } = props;

    if (pageContext.locale !== i18n.language) {
      i18n.changeLanguage(pageContext.locale);
      dayjs.locale(pageContext.locale)
    }
  }

  render() {
    const { transparent = false, children } = this.props;
    return (
      <div id="outer-container">
        <GlobalStyle />
        <StationsListFetch />
        <Header transparent={transparent} />
        <main id="page-wrap">{children}</main>
        <Footer />
        <AutoLogout />
        <Helmet>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.5.0/css/swiper.min.css"
          />
          <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"></meta>
        </Helmet>
      </div>
    );
  }
}
