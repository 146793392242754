import React from 'react';
import { Image } from 'rebass';
import { Link } from '../Link';

import BoxitLogoImage from './images/logo-header.svg';

/* Boxit logo */
const BoxitLogo = ({ url = '/', height, linkClassName, onClick, ...rest }) => (
  <Link to={url} className={linkClassName} onClick={onClick}>
    <Image
      src={BoxitLogoImage}
      alt="WEBOXIT"
      height={height || ['14px', '14px', '24px']}
      {...rest}
    />
  </Link>
);

export default BoxitLogo;
