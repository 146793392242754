// global-style.js
import { createGlobalStyle } from 'styled-components';
import reboot from 'styled-reboot';

// Options are, of course, optional, these are the default options
const options = {
  black: '#000',
  fontFamilyBase:
    '"PF BeauSans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  fontFamilyMonospace:
    'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
  fontSizeBase: '1rem',
  fontWeightBase: 400,
  fontStyleBase: 'normal',
  fontStretchBase: 'normal',
  lineHeightBase: 1.5,
  bodyColor: '#212529',
  bodyBg: '#fff',
  headingsMarginBottom: '0.5rem',
  paragraphMarginBottom: '1rem',
  labelMarginBottom: '0.5rem',
  dtFontWeight: 600,
  linkColor: 'none',
  linkColorVisited: 'none',
  linkDecoration: 'none',
  linkHoverColor: '#0056b3',
  linkHoverDecoration: 'none',
  tableCellPadding: '0.75rem',
  textMuted: '#6c757d',
};

const rebootCss = reboot(options);

const GlobalStyle = createGlobalStyle`
  ${rebootCss}  
  /* other styles */
  body {
    overflow-x: hidden;
    margin: 0;
  }
  
  /* styles for swiper carousel */
  .swiper-pagination-bullet {
    margin: 0 6px !important;
  }
  .swiper-pagination-bullet-active {
    background: #01549b !important;
  }  
  .navbar-active-link {
    :after {
      content: '';
      border: solid transparent;
      border-width: 0 14px 10px 14px;
      border-bottom-color: ${props => props.theme.colors.maize};
      position: relative;
      bottom: -17px;
      left: -50%;
      margin-left: -14px;
      margin-right: -14px;
    }  
  }
  .header-active-link {
    :after {
      content: '';
      border: solid transparent;
      border-width: 0 14px 10px 14px;
      border-bottom-color: ${props => props.theme.colors.white};
      position: relative;
      bottom: -21px;
      left: -50%;
      margin-left: -14px;
      margin-right: -14px;
    }  
  }
  .pos-navbar-active-link {
    color: ${props => props.theme.colors.maize} !important;
  }

  .pos-navbar-active-link-indicator{
    height: 30px;
    font-family: 'PF BeauSans Pro', -apple-system, sans-serif;
    font-size: 17px;
    text-decoration:none;
    padding-left: 60px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.14;
    letter-spacing: normal;
    color:  ${props => props.theme.colors.blues.peacock};
    :hover {
      color: ${props => props.theme.colors.blues.peacock};
    }
  }
  
  .pos-navbar-active-link-indicator {
    :after {
      content: '';
      border: solid transparent;
      border-width: 0 14px 10px 14px;
      border-bottom-color: white;
      position: relative;
      bottom: -17px;
      left: -50%;
      margin-left: -14px;
      margin-right: -14px;
    }  
  }

  /* styles for react-burger-menu sidebar */
   .bm-item-list [aria-current="page"] {
       background: #ffffff;
       color: #01549b !important;
   }
  /* For Firefox */
  input[type='number'] {
      -moz-appearance:textfield;
  }
  /* Webkit browsers like Safari and Chrome */
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
`;

export default GlobalStyle;
