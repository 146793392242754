import React from 'react';
import { Flex, Box } from 'rebass';

export const Container = ({ bg, children }) => (
  <Flex
    width="100%"
    bg={bg}
    css={{
      maxWidth: '1440px',
    }}
    mx="auto"
  >
    <Box
      px={['20px', '4%', '6%', '10%']}
      width="100%"
      css={{
        maxWidth: '1440px',
      }}
    >
      {children}
    </Box>
  </Flex>
);

export default Container;
