import styled from 'styled-components';
import { padding } from 'styled-system';

export const Button2 = styled.button`
  // width: ${props => (props.appended ? '54px' : 'auto')};
  height: 40px;
  border-radius: 4px;
  border: solid 1px #01549b;
  background-color: #01549b; 
  margin: 12px;

  font-family: 'PF BeauSans Pro';
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #ffffff;
`;

export const Button = styled.button`
  ${padding}
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : '40px')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '20px')};
  /* margin-right: 20px; */
  border-radius: 4px;
  border: ${props => (props.border ? props.border : 'solid 1px #01549b')};
  border-top-left-radius: ${props => (props.flatLeft ? 0 : '')};
  border-bottom-left-radius: ${props => (props.flatLeft ? 0 : '')};
  color: #ffffff;
  background-color: ${props => props.theme.colors.blues.peacock};
  text-align: center;
  font-size: ${props => (props.fontSize ? props.fontSize : '16px')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 'bold')};
  font-style: normal;
  font-stretch: normal;
  line-height: ${props =>
    props.lineHeight ? props.lineHeight : props.height ? props.height : '40px'};
  letter-spacing: ${props =>
    props.letterSpacing ? props.letterSpacing : '1px'};
  display: inline-block;
  cursor: pointer;
  &:hover {
    cursor: pointer;
    color: ${props => props.theme.colors.blues.peacock};
    background-color: ${props => props.theme.colors.maize};
  }
`;
